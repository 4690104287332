import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In case I need to say this -- these are just personal uninformed notes. :D This isn't any professional or whatever advice.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://www.daveramsey.com/dave-ramsey-7-baby-steps"
      }}>{`Dave Ramsey's Baby Steps`}</a></h2>
    <p>{`I read about this one in The Total Money Makeover (I still have that book somewhere).`}</p>
    <ol {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Save $1,000 emergency fund`}</li>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`pay off all debt except house using the snowball method.`}</li>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`save 3-6 months of expenses for a full emergency fund`}</li>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Invest 15% of income into retirement (that's interesting, I wonder why 15?)`}</li>
      <li parentName="ol">{`save for kids' college tuition/fund.`}</li>
      <li parentName="ol">{`pay off mortgage`}</li>
      <li parentName="ol">{`build further wealth`}</li>
    </ol>
    <p>{`Pros:`}</p>
    <ul>
      <li parentName="ul">{`straightfoward plan`}</li>
      <li parentName="ul">{`lots of resources by Ramsey for this`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`I keep hearing negative things about this plan. Unfortunately, I don't feel like I know enough to discern if the problems are valid or not.`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://www.reddit.com/r/personalfinance/wiki/commontopics"
      }}>{`/r/personal finance plan`}</a></h2>
    <p>{`(With some of my own notes)`}</p>
    <ol {...{
      "start": 0,
      "className": "contains-task-list"
    }}>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`budget/reduce expenses`}</li>
    </ol>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`setup YNAB`}</li>
    </ul>
    <ol {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`build an emergency fund`}</li>
    </ol>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`setup FDIC-insured savings account`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`3-6 months of expenses `}<em parentName="li">{`or`}</em>{` $1,000 while paying off debt`}</li>
    </ul>
    <ol {...{
      "start": 2,
      "className": "contains-task-list"
    }}>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`contribute max matching employer 401K (if not matching, skip)`}</li>
    </ol>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`invest full employer-matching (if 6% -> invest 6%)`}</li>
    </ul>
    <ol {...{
      "start": 3,
      "className": "contains-task-list"
    }}>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`pay down high interest debt`}</li>
      <li parentName="ol" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`contribute to IRA`}</li>
    </ol>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`open Roth or Traditional account`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`save up to 15% of gross income until reaching $6K limit`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`save income for retirement`}</li>
    </ol>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`save 15-20% of income`}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`save for other things`}</li>
    </ol>
    <h2>{`My steps`}</h2>
    <p>{`I wanted to combine what I've learned and add some notes. Not an accountant, this is mostly notes for me:`}</p>
    <h3>{`[ ]`}{` setup YNAB/budgetting`}</h3>
    <h3>{`[ ]`}{` open an accessible high-yield savings for $1,000`}</h3>
    <p>{`After checking several different plans, I think a $1,000 initial emergency savings is probably the best goal. Even prior to paying off debt. Why? Because emergencies come up and doing a $0-budget (assigning every $$ a job) and focusing on paying down debt means you'll be left with `}<em parentName="p">{`nothing`}</em>{` if an emergency happens.`}</p>
    <p>{`Important things to note:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`setup FDIC-insured savings account (just about any conventional bank savings account)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`find a high-yield savings`}</li>
    </ul>
    <p>{`I often see people recommend the likes of Ally bank which can yield multiple times more than a conventional Bank of America bank account. Not super important for this budget item; however, it'll set you up for a future step.`}</p>
    <p>{`Note: these accounts typically limit withdrawal to 6 withdrawals a month. It's not a checking account`}</p>
    <h3>{`[ ]`}{` Pay down debt`}</h3>
    <h3>{`[ ]`}{` Save 3-6 months of savings`}</h3>
    <p>{`To calculate this, figure out `}<em parentName="p">{`expenses`}</em>{`. They may not match your income, it's a separate idea. What's the minimum you need every month if you lose your job and need to look for a new one?`}</p>
    <p>{`And then, figure out how long you need for. Is it 3 months? 4? 6? I think /r/personalfinance has some guidelines around it.`}</p>
    <p>{`Important things to note:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`put this in a separate high-yield interest savings account (or the same if you can keep track of emergency vs non-emergency)`}</li>
    </ul>
    <p>{`I often see recommendations to split this savings up into several accounts like:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`first 1-2 months into the savings account`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`rest into more long-term investments (still need to research this)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      